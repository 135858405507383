/* eslint-disable no-var */
var widgetName = "brushfire-widget";
var apiName = "brushfire";
var widgetConfigName = widgetName + "Config";
var defaultconfig = {
	someDefaultConfiguration: false,
};

var lastSrc = "";
var lastWidgetId = "";
var widgetConfig = {};

function parseQueryString(query) {
	var vars = query.split("&");
	var queryString = {};
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split("=");
		var key = decodeURIComponent(pair[0]).toLowerCase();
		var value = decodeURIComponent(pair[1]);
		// If first entry with this name
		if (typeof queryString[key] === "undefined") {
			queryString[key] = decodeURIComponent(value);
			// If second entry with this name
		} else if (typeof queryString[key] === "string") {
			var arr = [queryString[key], decodeURIComponent(value)];
			queryString[key] = arr;
			// If third or later entry with this name
		} else {
			queryString[key].push(decodeURIComponent(value));
		}
	}
	return queryString;
}

function getBfData(bfData) {
	if (bfData) {
		return JSON.parse(atob(bfData));
	} else {
		return {};
	}
}

function getSource(params) {
	var action = params.action;
	var src = `https://widgetclient.${process.env.DOMAIN}${
		process?.env?.CLIENTPORT ? `:${process?.env?.CLIENTPORT}` : ""
	}`;
	switch (action) {
		case "register":
			if (params.clientKey && params.urlKey && params.eventNumber) {
				var newSrc = src + "/" + params.clientKey + "/" + params.urlKey + "/" + params.eventNumber + "/my";
				if (lastSrc.includes(newSrc)) {
					return lastSrc;
				}
				return newSrc;
			} else if (params.clientKey && params.urlKey) {
				return src + "/" + params.clientKey + "/" + params.urlKey;
			} else if (params.clientKey) {
				return src + "/" + params.clientKey;
			} else {
				return src + "/oops";
			}

		case "loader":
			console.log(window.gaSessions);
			console.log(JSON.stringify(window.gaSessions));
			console.log(btoa(JSON.stringify(window.gaSessions)));
			return (
				src +
				`/loading?dark=${params.darkMode === true || params.darkMode === "true"}&gaSessions=${btoa(
					JSON.stringify(window.gaSessions)
				)}`
			);
	}
}

var Style = {
	base: ["color: #fff", "background-color: #4A525A", "padding: 2px 4px", "border-radius: 2px"],
	warning: ["color: #fff", "background-color: #FACF43"],
	error: ["color: #fff", "background-color:  #E71D36"],
	success: ["color: #fff", "background-color: #20BF55"],
	brand: ["color: #fff", "background-color: #004bed"],
};
function log(text, extra = []) {
	var style = Style.base.join(";") + ";";
	style += extra.join(";"); // Add any additional styles
	console.log("%cBrushfire Widget: " + text, style);
}

function saveSessions() {
	window.gaSessions = {};
	if (!!window.gtag && !!window.dataLayer) {
		dataLayer.forEach(function(dl) {
			if (dl[0] && dl[0].toLowerCase() === "config" && dl[1] && dl[1].toLowerCase().substring(0, 2) === "g-") {
				var gaId = dl[1];
				if (!window.gaSessions[gaId]) {
					window.gaSessions[gaId] = {};
				}
				gtag("get", gaId, "client_id", (clientId) => {
					window.gaSessions[gaId].clientId = clientId;
				});
				gtag("get", gaId, "session_id", (sessionId) => {
					window.gaSessions[gaId].sessionId = sessionId;
				});
			}
		});
	}
}

var validParams = [
	"widgetId",
	"clientKey",
	"urlKey",
	"eventNumber",
	"action",
	"darkMode",
	"debugMode",
	"view",
	"startsAt",
	"endsAt",
	"search",
	"promo",
	"access",
	"preview",
	"typeCode",
	"group",
	"all",
	"type",
	"typeQty",
	"start",
	"lookupId",
	"orderId",
	"attendeeId",
	"attendeeAction",
	"groupId",
	"groupAction",
	"path",
	"calendar",
];

var actionParams = {
	list: {
		required: ["widgetId", "clientKey"],
		optional: ["urlKey"],
		search: ["startsAt", "endsAt", "view", "search"],
	},
	register: {
		required: ["widgetId", "clientKey", "urlKey", "eventNumber"],
		optional: ["start"],
		search: ["promo", "access", "preview", "typeCode", "groupId", "joinCode", "type", "typeQty"],
	},
	lookup: {
		required: ["widgetId", "clientKey", "urlKey", "eventNumber"],
		optional: ["lookupId"],
	},
	attendees: {
		required: ["widgetId", "clientKey", "urlKey", "eventNumber"],
		optional: ["attendeeId", "attendeeAction"],
	},
	orders: {
		required: ["widgetId", "clientKey", "urlKey", "eventNumber"],
		optional: ["orderId"],
	},
	group: {
		required: ["widgetId", "clientKey", "urlKey", "eventNumber"],
		optional: ["groupId"],
	},
};

function validateParams(bfParams) {
	Object.keys(bfParams).forEach(function(key) {
		if (!validParams.includes(key)) {
			log(`${key} is not a valid parameter`, Style.error);
		}
	});
}

function checkButtons(bfParams, buttons) {
	var index = -1;
	if (bfParams.eventNumber) {
		index = buttons.findIndex(function(button) {
			return button.dataset.eventnumber === bfParams.eventNumber;
		});
	} else if (bfParams.urlKey) {
		index = buttons.findIndex(function(button) {
			return button.dataset.urlkey === bfParams.urlKey;
		});
	} else if (bfParams.clientKey) {
		index = buttons.findIndex(function(button) {
			return button.dataset.clientkey === bfParams.clientKey;
		});
	}
	return index;
}

function apiHandler(api, params) {
	if (window.brushfireDebugMode === true) {
		log(`Client Brushfire API method: ${api}`, Style.brand);
	}
	if (!api) throw Error("API method required");
	api = api.toLowerCase();
	var config = window[widgetConfigName];

	switch (api) {
		case "init":
			config = Object.assign({}, config, params);
			window[widgetConfigName] = config;
			// get a reference to the created widget component so we can
			// call methods as needed
			saveSessions();
			if (window.top.location.href === window.location.href) {
				document.head.insertAdjacentHTML(
					"beforeend",
					`<link rel="StyleSheet" href="https://widgetclient.${process.env.DOMAIN}${
						process?.env?.CLIENTPORT ? `:${process?.env?.CLIENTPORT}` : ""
					}/brushfire.min.css" type="text/css" />`
				);
				document.head.insertAdjacentHTML(
					"beforeend",
					`<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />`
				);
				// var div = document.createElement("div");
				// div.id = "brushfire-wrap";

				// // Move the body's children into this wrapper
				// while (document.body.firstChild) {
				// 	div.appendChild(document.body.firstChild);
				// }

				// // Append the wrapper to the body
				// document.body.appendChild(div);
				document.body.insertAdjacentHTML(
					"beforeend",
					`<div id="brushfire-widget-modal" class="brushfire-widget-modal"></div>`
				);

				if (window.location.search.length > 1 && window.location.search.includes("brushfire=")) {
					var urlParams = parseQueryString(window.location.search.substring(1));
					var bfParams = getBfData(urlParams["brushfire"]);
					bfParams.action = bfParams.action ?? "smart";
					validateParams(bfParams);
					if (bfParams && bfParams.action) {
						if (!bfParams.widgetId) {
							var buttons = document.querySelectorAll("[data-brushfire]");
							var index = checkButtons(bfParams, buttons);
							if (index === -1) {
								if (buttons[0] && buttons[0].dataset.brushfire) {
									bfParams.widgetId = buttons[0].dataset.brushfire;
								} else {
									log(
										"Unable to autoload the Brushfire Widget. Make sure you include a Brushfire Widget Button somewhere on this page",
										Style.error
									);
								}
							} else {
								bfParams.widgetId = buttons[index].dataset.brushfire;
							}
						} else {
							bfParams.u = "*";
						}
						apiHandler("open", bfParams);
					}
				}

				window.onpopstate = function(event) {
					if (event.state === undefined || event.state === null || event.state.bfAction === undefined) {
						brushfire("closeFromBackButton");
					}
				};
			}
			break;
		case "open":
			var bfWidgetModal = document.getElementById("brushfire-widget-modal");
			var existingIframe = document.getElementById("brushfire-widget");
			var src = getSource(params);
			var loaderSrc = getSource({ action: "loader", darkMode: params.darkMode });
			if (params.widgetId || params.u) {
				if (existingIframe) {
					if (lastWidgetId !== params.widgetId) {
						existingIframe.src = loaderSrc;

						existingIframe.contentWindow.postMessage(
							JSON.stringify({
								action: "setWidgetId",
								widgetId: params.widgetId,
								widgetConfig: params,
							}),
							"*"
						);
						lastWidgetId = params.widgetId;
						lastSrc = src;
						existingIframe.src = src;
					} else {
						if (lastSrc !== src) {
							lastSrc = src;
							existingIframe.src = src;
						}
					}

					existingIframe.contentWindow.postMessage(
						JSON.stringify({
							action: "setAccount",
							account: window.bfExternalAccount ?? undefined,
						}),
						"*"
					);
				} else {
					bfWidgetModal.insertAdjacentHTML(
						"beforeend",
						`<iframe
					id="brushfire-widget"
					allowtransparency="true"
					allow="payment *; geolocation *"
					src="` +
							loaderSrc +
							`"
					></iframe>`
					);
					var bfWidget = document.getElementById("brushfire-widget");
					bfWidget.onload = function() {
						setTimeout(function() {
							bfWidget.contentWindow.postMessage(
								JSON.stringify({
									action: "setHost",
									host: window.xreferral ? window.xreferral : window.location.host,
								}),
								"*"
							);
							bfWidget.contentWindow.postMessage(
								JSON.stringify({
									action: "setWidgetId",
									widgetId: params.widgetId,
									widgetConfig: params,
								}),
								"*"
							);
							lastWidgetId = params.widgetId;
						}, 200);
						setTimeout(function() {
							bfWidget.contentWindow.postMessage(
								JSON.stringify({
									action: "setAccount",
									account: window.bfExternalAccount ?? undefined,
								}),
								"*"
							);
						}, 400);
					};
				}

				bfWidgetModal.classList.add("active");

				history.pushState({ bfAction: "open", url: src }, document.title);
			} else {
				log(
					"Widget Id 'widgetId' not set. Please register your widget @ https://widget.brushfire.com to get started.",
					Style.error
				);
			}
			break;
		case "close":
			var bfWidgetModal = document.getElementById("brushfire-widget-modal");
			var existingIframe = document.getElementById("brushfire-widget");
			lastSrc = existingIframe.src;
			if (bfWidgetModal.classList && bfWidgetModal.classList.contains("active")) {
				var scrollY = document.body.style.top;
				window.scrollTo(0, parseInt(scrollY || "0") * -1);
				bfWidgetModal.classList.remove("active");
				setTimeout(function() {
					existingIframe.remove();
				}, 500);
			}

			break;
		case "closefrombackbutton":
			var bfWidgetModal = document.getElementById("brushfire-widget-modal");
			var existingIframe = document.getElementById("brushfire-widget");
			lastSrc = existingIframe.src;
			if (bfWidgetModal.classList && bfWidgetModal.classList.contains("active")) {
				bfWidgetModal.classList.remove("active");
				setTimeout(function() {
					existingIframe.remove();
				}, 500);
			}

			break;
		case "button":
			if (params.widgetId) {
				var button = document.getElementById(params.id);
				if (button) {
					button.classList.add("brushfire-button");
					var open = function() {
						apiHandler("open", params);
					};
					button.removeEventListener("click", open);
					button.addEventListener("click", open);
				} else {
					throw Error("Cannot find DOM element with id " + params.id);
				}
				break;
			} else {
				throw Error(
					"Widget Id 'widgetId' not set. Please register your widget @ https://widget.brushfire.com to get started."
				);
			}
		case "setaccount":
			window.bfExternalAccount = params;
			break;
	}
}

function app(window) {
	// If we don't already have a name for widget's global object
	// assigned by the host, then they must be using the simple <script> tag method
	// so we need to get our data out of that tag
	if (window.top.location.href === window.location.href) {
		if (!window[widgetName]) {
			window[widgetName] = apiName;

			var placeholder = { q: [] };
			placeholder.q.push(["init", {}]);

			window[window[widgetName]] = placeholder;

			//Attach button handler
			var handleButton = function(el) {
				const params = {
					widgetId: el.dataset.brushfire,
					action: el.dataset.action,

					clientKey: el.dataset.clientkey,
					urlKey: el.dataset.urlkey,
					eventNumber: el.dataset.eventnumber,

					darkMode: el.dataset.dark,
					debugMode: el.dataset.debug,
					view: el.dataset.view,
					startsAt: el.dataset.startsat,
					endsAt: el.dataset.endsat,
					search: el.dataset.search,
					promo: el.dataset.promo,
					access: el.dataset.access,
					preview: el.dataset.preview,
					typeCode: el.dataset.typecode,
					group: el.dataset.group,
					all: el.dataset.all,
					type: el.dataset.type,
					typeQty: el.dataset.typeqty,
					start: el.dataset.start,
					lookupId: el.dataset.lookupid,
					orderId: el.dataset.orderid,
					path: el.dataset.path,

					attendeeId: el.dataset.attendeeid,
					attendeeAction: el.dataset.attendeeaction,

					groupId: el.dataset.groupid,
					groupAction: el.dataset.groupaction,
				};
				validateParams(params);
				apiHandler("open", params);
			};

			document.addEventListener("click", function(event) {
				if (event.target.dataset.brushfire !== undefined) {
					handleButton(event.target);
				}
			});

			document.querySelectorAll("[data-brushfire]").forEach(function(el) {
				if (el.dataset.debug === "true") {
					window.brushfireDebugMode = true;
				}
				el.addEventListener("click", function(event) {
					event.stopPropagation();
					event.preventDefault();
					handleButton(el);
				});
			});
		}

		var placeholder = window[window[widgetName]];

		// override temporary (until the app loaded) handler
		// for widget's API calls
		window[window[widgetName]] = apiHandler;
		window[widgetConfigName] = defaultconfig;

		if (placeholder) {
			var queue = placeholder.q;
			if (queue) {
				for (var i = 0; i < queue.length; i++) {
					apiHandler(queue[i][0], queue[i][1]);
				}
			}
		} else {
			log(`${widgetName} placeholder not found`, Style.error);
		}

		var actualCode = `window.addEventListener("message", function (event){ 
				try{
					if(event.data.event && event.data.event === "bfAnalytics.Message"){
						gtag("event", event.data.bfAnalytics[1], event.data.bfAnalytics[2]);
					}else{
						var data = JSON.parse(event.data);
						switch(data.action){
							case "close":
								brushfire("close");
								break;
							case "push":
								break;
							case "replace":
								break;
							case "reload":
								window.location.reload();
								break;
							case "redirect":
								if (data.url) {
									window.location.href = data.url
								}
						}
					}
				}catch(err){
					
				}
				
			});`;

		var script = document.createElement("script");
		script.textContent = actualCode;
		(document.head || document.documentElement).appendChild(script);
	}
}

function docReady(fn) {
	// see if DOM is already available
	if (document.readyState === "complete" || document.readyState === "interactive") {
		// call on next available tick
		setTimeout(fn, 1);
	} else {
		document.addEventListener("DOMContentLoaded", fn);
	}
}

docReady(function() {
	app(window);
});
